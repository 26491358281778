import styled from 'styled-components';

const RoutersWrapper = styled.div`
  .ant-form-item {
    margin-bottom: 10px;
    text-align: left;
  }
`;

export default RoutersWrapper;
